<template>
  <div>
    <div class="content-news" v-if="list.length > 0">
      <div
        class="m-2 content-privilege-group"
        v-for="(item, index) in list"
        :key="index"
      >
        <b-row class="list-pri-content">
          <b-col cols="5">
            <div>
              <b-img-lazy
                v-bind="mainProps"
                :src="item.image_url"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
            </div>
          </b-col>
          <b-col cols="7">
            <div class="title line-clamp-2">{{ item.name }}</div>
            <div class="description line-clamp-2">
              {{ item.short_description }}
            </div>
          </b-col>
        </b-row>
        <b-row class="p-2">
          <b-col cols="7">
            <div class="expired-date">
              วันที่สร้าง:
              {{ item.created_date ? dateFormat(item.created_date) : "-" }}
            </div>
          </b-col>
          <b-col cols="5">
            <div
              class="see-all text-right"
              @click="seeDetailPrivilege(item.id)"
            >
              ดูรายละเอียด
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else>
      <div class="notfound-data">ไม่พบข้อมูลข่าวสาร</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      }
    };
  },
  methods: {
    seeDetailPrivilege(id) {
      this.$router.push("/news/" + id);
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
      // return moment(date).locale("th").format('lll');
    }
  }
};
</script>

<style lang="scss" scoped>
.lazy-image {
  font-size: 1px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-privilege-group {
  background-color: #f0f0f0;
}
.list-pri-content {
  border-bottom: 1px dashed rgb(170, 170, 170);
  margin: 0 0;
  padding: 5px 0;
}
.title {
  font-weight: 600;
}
.description {
  font-size: 13px;
  color: #4b4b4b;
}
.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.expired-date {
  font-size: 12px;
}
.see-all {
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
}
.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.notfound-data {
  color: #fff;
  text-align: center;
  padding: 50px 0;
}
.content-news {
  background-color: #fff;
  margin: 1rem;
  border-radius: 5px;
  padding: 1px;
}
</style>
